import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  tooLongtitle: {
    color: "white",
    maxWidth: 200,
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "2rem",
    },
  },
  tooLongSubheader: {
    color: "silver",
    // backgroundColor: "green",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "650px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
      // maxWidth: "600px",
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 360,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
      paddingTop: 40,
    },
  },
  exampleHeader: {
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 360,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
      paddingTop: 40,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  figText: {
    fontSize: "1.1rem",
    textAlign: "left",
    fontStyle: "italic",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: "280px",
    },
  },
  introContainer: {
    paddingTop: 120,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  spacer: {
    height: 40,
  },
  aspectsSection: {
    backgroundColor: "#080808",
    width: "100%",
    padding: "80px 0",
    margin: 0,
  },
  aspectsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 0,
    margin: "0 auto",
    maxWidth: "1200px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  aspectItem: {
    width: "100%",
    maxWidth: "800px",
    padding: "40px 0",
    textWrap: "balance",
  },
  aspectHeader: {
    color: "white",
    fontWeight: 300,
    marginBottom: "20px",
  },
  localLink: {
    backgroundColor: "#9B969A",
    color: "white",
    fontSize: "1rem",
    textDecoration: "none",
    padding: "10px 20px",
    borderRadius: "50px",
    display: "inline-block",
    textAlign: "center",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    marginBottom: "40px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#8A8489",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    },
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "1em",
    marginBottom: "2em",
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "8px 0",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    flexWrap: "wrap",
    gap: "12px",
  },
  tableCellLabel: {
    color: "white",
    fontSize: "1.05rem",
    textAlign: "left",
    flex: "1 1 auto",
    minWidth: 0,
  },
  tableCellPrice: {
    color: "white",
    fontWeight: 500,
    fontSize: "1.05rem",
    textAlign: "right",
    flex: "0 0 auto",
    whiteSpace: "normal", // allow wrapping
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      textAlign: "right",
      marginLeft: 0,
    },
  },
  tableNote: {
    display: "block",
    fontWeight: 300,
    fontSize: "0.95rem",
    color: "#ccc",
    marginTop: "4px",
    textAlign: "right",
  },
}))

const PricePage = ({ intl, data, location }) => {
  const classes = useStyles()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid

  const renderGroup = (groupTitle, sections) => (
    <div className={classes.aspectItem}>
      <Typography variant="h4" component="h3" className={classes.aspectHeader}>
        {groupTitle}
      </Typography>
      {sections.map((section, i) => (
        <div key={i}>
          <Typography
            variant="h5"
            component="h4"
            className={classes.aspectHeader}
            style={{ fontSize: "1.3rem", marginTop: "2em", textAlign: "left" }}
          >
            {section.title}
          </Typography>

          <div className={classes.table}>
            {section.items.map((item, idx) => (
              <div key={idx} className={classes.tableRow}>
                <div className={classes.tableCellLabel}>{item.label}</div>
                <div className={classes.tableCellPrice}>
                  <strong>{item.price}</strong>
                  {item.note && (
                    <span className={classes.tableNote}> {item.note}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        // title={intl.formatMessage({ id: "maleFacelift.seoTitle" })}
        // description={intl.formatMessage({ id: "maleFacelift.seoDescription" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <section>
          <BackgroundImage
            fluid={banner}
            className={classes.backgroundImg}
            backgroundColor={`#333`}
          >
            <Grid container spacing={0} className={classes.bannerGrid}>
              <Grid item xs={12} sm={4} className={classes.bannerLeft}>
                <Container>
                  <LineTitle
                    header={t("price.title")}
                    subheader={t("price.subtitle")}
                    titleStyle={classes.tooLongtitle}
                    lightDecoLine
                    subHeaderStyle={classes.tooLongSubheader}
                  />
                </Container>
              </Grid>
              <Grid item xs={12} sm={8}></Grid>
            </Grid>
          </BackgroundImage>
          <div className={classes.spacer} />
          <Grid container spacing={12} className={classes.introContainer}>
            <Grid item xs={12} sm={6} align="center">
              <Typography
                component="h2"
                variant="h3"
                className={classes.introHeader}
              >
                {t("price.introTitle")}
              </Typography>
              <p className={classes.bodyText}>{t("price.introText")}</p>
              <Link
                variant="button"
                to={"/deep-plane-facelift"}
                className={classes.localLink}
              >
                See example results
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Img
                fluid={data.docs.childImageSharp.fluid}
                alt="before and after deep plane facelift example"
              />
            </Grid>
          </Grid>
        </section>
        <section className={classes.aspectsSection}>
          <div className={classes.aspectsContainer}>
            {renderGroup(
              "Non-Surgical Treatments",
              JSON.parse(t("price.groups.nonSurgical"))
            )}
            {renderGroup(
              "Surgical Treatments",
              JSON.parse(t("price.groups.surgical"))
            )}
            {renderGroup("Other", JSON.parse(t("price.groups.other")))}
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default injectIntl(PricePage)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "heros/price-banner.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    docs: file(relativePath: { eq: "susan-schmidt-callum-faris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
